import React from 'react'
import Container from '../Container/Container'
import './Hero.scss'

function Hero() {
	return (
		<section className='hero'>
			<Container>
				<div className='hero__container'>
					<div className='hero__text-wrapper'>
						<h1 className='hero__heading'>Izlamasdan topamiz</h1>
						<p className='hero__desc'>
						Abutech butun dunyo bo‘ylab mijozlarimizga eng yaxshi xizmatlarni taqdim etish huquqiga ega.
						</p>
					</div>
				</div>
			</Container>
		</section>
	)
}

export default Hero
