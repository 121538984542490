import "./FormInner.scss"
function FormInner () {
    return (
        <>
            <div className="form-inner">
                <h2 className="form-inner__title">есть новый или существующий проект?</h2>
                <p className="form-inner__text">СВЯЖИТЕСЬ И МЫ <span> РЕАЛИЗИРУЕМ </span>ВАШИ ИДЕИ!</p>
            </div>
        </>
    );
}

export default FormInner