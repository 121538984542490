import React from 'react'
import { Link } from 'react-router-dom'
import Container from '../Container/Container'
import './FooterBottom.scss'
import IconNext from '../Lib/Svg/IconNext'

function FooterBottom() {
    return (
        <div className='site-footer__bottom'>
            <Container>
                <div className='site-footer__bottom-header'>
                    <h2 className='site-footer__bottom-heading'>
                    Ajoyib narsa qilishni xohlaysizmi? Keling, sizga yordam beramiz.
                    </h2>
                    <p className='site-footer__bottom-paragraph'>
                    Tayyormisiz? Bugun bog'laning.
                    </p>
                </div>

                <div className='site-footer__bottom-content'>
                    <ul className='site-footer__bottom-left'>
                        <li className='site-footer__bottom-left-item'>
                            <h3 className='site-footer__bottom-left-heading'>
                            Biz bilan bog'lanish!
                            </h3>
                            <a
                                className='site-footer__bottom-link'
                                href='mailto:admin@abutech.uz'
                                target='blank'>
                                admin@abutech.uz
                            </a>
                        </li>
                        <li className='site-footer__bottom-left-item'>
                            <h3 className='site-footer__bottom-left-heading'>
                                Telefon raqam
                            </h3>
                            <a
                                className='site-footer__bottom-link'
                                href='tel:+998996130011'
                                target='blank'>
                                +998 99 613 00 11
                            </a>
                        </li>
                        <li className='site-footer__bottom-left-item'>
                            <h3 className='site-footer__bottom-left-heading'>
                                Bizning ofis
                            </h3>
                            <address className='site-footer__bottom-link'>
                            Chilonzor tumani Qatortol ko'chasi, 1 uy
                            </address>
                        </li>

                        <li className='site-footer__bottom-left-item'>
                            <h3 className='site-footer__bottom-left-heading'>
                                Ijtimoiy tarmoqlarimiz
                            </h3>
                            <div className='site-footer__bottom-link-wrapper'>
                                <a
                                    className='site-footer__bottom-link'
                                    href='https://www.linkedin.com/company/abutech/' target="_blank">
                                    Linkedin
                                </a>
                                <a
                                    className='site-footer__bottom-link'
                                    href='https://www.instagram.com/abutech.uz/'
                                        target='_blank'
                                    >
                                    Instagram
                                </a>
                                <a
                                    className='site-footer__bottom-link'
                                    href='https://t.me/abutechuz'
                                    target="_blank"
                                    >
                                        
                                    Telegram
                                </a>
                                <a
                                    className='site-footer__bottom-link'
                                    href='https://www.behance.net/abutechdigital?tracking_source=search_projects%7Cabutech'
                                
                                    target='_blank'>
                                    Behance
                                </a>
                            </div>
                        </li>
                    </ul>
                    {/* <ul className='site-footer__bottom-right'>
                        <li className='site-footer__bottom-right-item'>
                            <Link
                                className='site-footer__bottom-right-link site-footer__bottom-right-link--first'
                                to='/services'>
                                Ознакомиться с услугами
                                <IconNext/>
                            </Link>
                        </li>
                        <li className='site-footer__bottom-right-item'>
                            <Link
                                className='site-footer__bottom-right-link'
                                to='/jobs'>
                                Посмотреть работы
                                <IconNext/>

                            </Link>
                        </li>
                    </ul> */}
                </div>
            </Container>
        </div>
    )
}

export default FooterBottom
