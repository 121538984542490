import React from 'react'

function IconIdea() {
    return (
        <svg
            width={28}
            height={40}
            viewBox='0 0 28 40'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M27.7046 14.0516C27.7046 6.30349 21.4905 0 13.8523 0C11.1767 0 8.5798 0.775349 6.34248 2.24218C4.16574 3.66938 2.43048 5.67717 1.32435 8.04847C1.16064 8.39945 1.31247 8.81671 1.66336 8.98042C2.01462 9.14431 2.4316 8.99229 2.59531 8.64141C4.64649 4.2439 9.06513 1.40242 13.8523 1.40242C20.7172 1.40242 26.3022 7.07678 26.3022 14.0516C26.3022 17.3644 25.0503 20.4965 22.7773 22.8706C21.4672 24.2389 20.352 25.5463 20.065 27.3783H7.82296C7.46142 25.4765 6.16418 24.1422 4.99849 22.9443C2.67955 20.5611 1.40242 17.4029 1.40242 14.0516C1.40242 13.224 1.48161 12.3964 1.63765 11.5915C1.71142 11.2114 1.463 10.8434 1.08276 10.7696C0.702611 10.6964 0.334617 10.9444 0.26085 11.3246C0.0876979 12.2172 0 13.1347 0 14.0516C0 17.7703 1.41822 21.2759 3.99343 23.9223C5.47242 25.4422 6.51937 26.6554 6.51937 28.4335C6.51937 28.4397 6.51946 28.4461 6.51965 28.4522L6.55985 32.5672C6.55985 33.9022 7.272 35.074 8.33624 35.7228V36.494C8.33624 38.4272 9.90901 40 11.8423 40H16.143C18.0763 40 19.6491 38.4272 19.6491 36.494V35.7228C20.7133 35.074 21.4255 33.9022 21.4255 32.5672C21.4255 32.5672 21.4205 27.9835 21.4112 27.9378C21.5442 26.3127 22.5293 25.1576 23.7903 23.8404C26.3144 21.204 27.7046 17.7275 27.7046 14.0516ZM18.2466 36.494C18.2466 37.6539 17.3028 38.5976 16.1429 38.5976H11.8422C10.6823 38.5976 9.73857 37.6539 9.73857 36.494V36.2237C9.90676 36.2472 10.0782 36.2602 10.2528 36.2602H17.7323C17.9069 36.2602 18.0784 36.2471 18.2466 36.2237V36.494ZM17.7323 34.8578H10.2528C8.98968 34.8578 7.96217 33.8303 7.96217 32.5672V32.5204H15.0677C15.4551 32.5204 15.769 32.2066 15.769 31.8192C15.769 31.4319 15.4551 31.118 15.0677 31.118H7.96217V28.7807H20.0013L20.023 31.118H18.3401C17.9527 31.118 17.6388 31.4319 17.6388 31.8192C17.6388 32.2066 17.9527 32.5204 18.3401 32.5204H20.023V32.5672C20.023 33.8303 18.9954 34.8578 17.7323 34.8578Z'
                fill='currentColor'
            />
            <path
                d='M18.4109 14.1447C18.1735 14.4505 18.2289 14.891 18.5347 15.1285C18.8405 15.3661 19.281 15.3107 19.5186 15.0047L20.9068 13.217C21.2744 12.7436 21.4356 12.1555 21.3608 11.5608C21.2859 10.966 20.984 10.4361 20.5105 10.0685C20.0372 9.70086 19.4486 9.53875 18.8543 9.61457C18.2596 9.68937 17.7296 9.99126 17.3621 10.4647L12.8333 16.2973L10.2342 13.4215C9.40443 12.5037 7.98267 12.4321 7.06502 13.2615C6.62036 13.6634 6.35885 14.2144 6.32856 14.813C6.29836 15.4116 6.50302 15.9861 6.90486 16.4309L11.2992 21.2926C12.1837 22.3186 13.9316 22.2557 14.7361 21.1641L17.5115 17.5898C17.749 17.284 17.6936 16.8435 17.3877 16.606C17.0819 16.3685 16.6415 16.4238 16.4039 16.7298L13.6285 20.3041C13.3273 20.7134 12.6699 20.7372 12.3397 20.3522L7.94546 15.4905C7.63431 15.1463 7.66123 14.6132 8.00557 14.302C8.34963 13.9912 8.88283 14.0178 9.19398 14.3621L12.3541 17.8584C12.6295 18.1778 13.177 18.1595 13.4282 17.8182L18.47 11.325C18.7399 10.962 19.2996 10.8916 19.6507 11.1764C20.0137 11.4464 20.084 12.0056 19.7992 12.3571L18.4109 14.1447Z'
                fill='currentColor'
            />
        </svg>
    )
}

export default IconIdea
