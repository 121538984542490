import React from 'react'

function IconTest() {
    return (
        <svg
            width={32}
            height={40}
            viewBox='0 0 32 40'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M28.1412 9.39388C27.8176 9.39388 27.5552 9.65622 27.5552 9.97982V36.2831C27.5552 36.3053 27.5372 36.3234 27.5149 36.3234H6.84831C6.52471 36.3234 6.26237 36.5857 6.26237 36.9093C6.26237 37.2329 6.52471 37.4953 6.84831 37.4953H27.5149C28.1833 37.4953 28.7271 36.9515 28.7271 36.2831V9.97982C28.7271 9.65622 28.4648 9.39388 28.1412 9.39388Z'
                fill='currentColor'
            />
            <path
                d='M30.0202 2.505H24.6265L22.66 0.538517C22.3128 0.19125 21.8511 0 21.36 0H1.21219C0.543751 0 0 0.543751 0 1.21219V36.2828C0 36.9512 0.543751 37.495 1.21219 37.495H2.50501V38.7878C2.50501 39.4562 3.04876 40 3.7172 40H30.0202C30.6887 40 31.2324 39.4562 31.2324 38.7878V3.71727C31.2324 3.04883 30.6887 2.505 30.0202 2.505ZM21.8385 1.37422L27.3531 6.88884H21.8788C21.8565 6.88884 21.8385 6.8708 21.8385 6.84853V1.37422ZM30.0605 38.7879C30.0605 38.8102 30.0425 38.8282 30.0202 38.8282H3.7172C3.69493 38.8282 3.67688 38.8102 3.67688 38.7879V37.4951H4.34329C4.66688 37.4951 4.92923 37.2327 4.92923 36.9091C4.92923 36.5855 4.66688 36.3232 4.34329 36.3232H1.21219C1.18992 36.3232 1.17188 36.3052 1.17188 36.2829V1.21219C1.17188 1.18992 1.18992 1.17188 1.21219 1.17188H20.6666V6.84853C20.6666 7.51697 21.2104 8.06072 21.8788 8.06072H28.1415C28.4651 8.06072 28.7274 7.79838 28.7274 7.47478V7.36736C28.7274 6.87634 28.5361 6.41462 28.1889 6.06736L25.7984 3.67688H30.0202C30.0425 3.67688 30.0605 3.69493 30.0605 3.7172V38.7879Z'
                fill='currentColor'
            />
            <path
                d='M11.6463 6.69376C11.4175 6.46493 11.0465 6.46493 10.8177 6.69376L7.50293 10.0085C7.48715 10.0242 7.4616 10.0242 7.4459 10.0085L6.12449 8.68712H7.14168C7.46527 8.68712 7.72762 8.42478 7.72762 8.10118C7.72762 7.77759 7.46527 7.51524 7.14168 7.51524H5.59558C5.40824 7.51524 5.23136 7.55923 5.07277 7.63548L4.75738 7.32009C4.52855 7.09126 4.15753 7.09126 3.92878 7.32009C3.69995 7.54891 3.69995 7.91993 3.92878 8.14868L4.38941 8.60931C4.38566 8.64814 4.38347 8.68751 4.38347 8.72735V12.4849C4.38347 13.1534 4.92722 13.6971 5.59566 13.6971H9.35325C10.0217 13.6971 10.5654 13.1534 10.5654 12.4849V8.60321L11.6463 7.52235C11.8751 7.29352 11.8751 6.92251 11.6463 6.69376ZM9.39356 12.485C9.39356 12.5073 9.37551 12.5253 9.35325 12.5253H5.59566C5.5734 12.5253 5.55535 12.5073 5.55535 12.485V9.77509L6.6173 10.837C6.85363 11.0734 7.16402 11.1916 7.47449 11.1916C7.78488 11.1916 8.09528 11.0734 8.3316 10.837L9.39356 9.77509V12.485Z'
                fill='currentColor'
            />
            <path
                d='M9.35324 15.6566H5.59565C4.92721 15.6566 4.38346 16.2003 4.38346 16.8688V20.6264C4.38346 21.2948 4.92721 21.8385 5.59565 21.8385H9.35324C10.0217 21.8385 10.5654 21.2948 10.5654 20.6264V16.8688C10.5654 16.2004 10.0217 15.6566 9.35324 15.6566ZM9.39355 20.6264C9.39355 20.6486 9.3755 20.6667 9.35324 20.6667H5.59565C5.57339 20.6667 5.55534 20.6486 5.55534 20.6264V16.8688C5.55534 16.8465 5.57339 16.8285 5.59565 16.8285H9.35324C9.3755 16.8285 9.39355 16.8465 9.39355 16.8688V20.6264Z'
                fill='currentColor'
            />
            <path
                d='M9.35324 23.7979H5.59565C4.92721 23.7979 4.38346 24.3416 4.38346 25.01V28.7676C4.38346 29.4361 4.92721 29.9798 5.59565 29.9798H9.35324C10.0217 29.9798 10.5654 29.436 10.5654 28.7676V25.01C10.5654 24.3416 10.0217 23.7979 9.35324 23.7979ZM9.39355 28.7676C9.39355 28.7899 9.3755 28.8079 9.35324 28.8079H5.59565C5.57339 28.8079 5.55534 28.7898 5.55534 28.7676V25.01C5.55534 24.9878 5.57339 24.9697 5.59565 24.9697H9.35324C9.3755 24.9697 9.39355 24.9878 9.39355 25.01V28.7676Z'
                fill='currentColor'
            />
            <path
                d='M18.7477 8.76758H13.1113C12.7877 8.76758 12.5254 9.02992 12.5254 9.35352C12.5254 9.67711 12.7877 9.93946 13.1113 9.93946H18.7477C19.0713 9.93946 19.3336 9.67711 19.3336 9.35352C19.3336 9.02992 19.0713 8.76758 18.7477 8.76758Z'
                fill='currentColor'
            />
            <path
                d='M23.7578 11.2728H13.1113C12.7877 11.2728 12.5254 11.5351 12.5254 11.8587C12.5254 12.1823 12.7877 12.4447 13.1113 12.4447H23.7578C24.0814 12.4447 24.3437 12.1823 24.3437 11.8587C24.3437 11.5351 24.0814 11.2728 23.7578 11.2728Z'
                fill='currentColor'
            />
            <path
                d='M18.7477 16.9092H13.1113C12.7877 16.9092 12.5254 17.1715 12.5254 17.4951C12.5254 17.8187 12.7877 18.0811 13.1113 18.0811H18.7477C19.0713 18.0811 19.3336 17.8187 19.3336 17.4951C19.3336 17.1715 19.0713 16.9092 18.7477 16.9092Z'
                fill='currentColor'
            />
            <path
                d='M23.7578 19.4141H13.1113C12.7877 19.4141 12.5254 19.6764 12.5254 20C12.5254 20.3236 12.7877 20.5859 13.1113 20.5859H23.7578C24.0814 20.5859 24.3437 20.3236 24.3437 20C24.3437 19.6764 24.0814 19.4141 23.7578 19.4141Z'
                fill='currentColor'
            />
            <path
                d='M18.7477 25.0505H13.1113C12.7877 25.0505 12.5254 25.3128 12.5254 25.6364C12.5254 25.96 12.7877 26.2223 13.1113 26.2223H18.7477C19.0713 26.2223 19.3336 25.96 19.3336 25.6364C19.3336 25.3128 19.0713 25.0505 18.7477 25.0505Z'
                fill='currentColor'
            />
            <path
                d='M23.7578 27.5553H13.1113C12.7877 27.5553 12.5254 27.8177 12.5254 28.1413C12.5254 28.4649 12.7877 28.7272 13.1113 28.7272H23.7578C24.0814 28.7272 24.3437 28.4649 24.3437 28.1413C24.3437 27.8177 24.0814 27.5553 23.7578 27.5553Z'
                fill='currentColor'
            />
        </svg>
    )
}

export default IconTest
