function IconCalendar() {
    return (
        <svg
            width={18}
            height={18}
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.25 12C5.25 11.5875 5.5875 11.25 6 11.25C6.4125 11.25 6.75 11.5875 6.75 12C6.75 12.4125 6.4125 12.75 6 12.75C5.5875 12.75 5.25 12.4125 5.25 12ZM9 11.25H12C12.4125 11.25 12.75 11.5875 12.75 12C12.75 12.4125 12.4125 12.75 12 12.75H9C8.5875 12.75 8.25 12.4125 8.25 12C8.25 11.5875 8.5875 11.25 9 11.25ZM13.5 15H4.5C4.08675 15 3.75 14.6632 3.75 14.25V9.75H14.25V14.25C14.25 14.6632 13.9132 15 13.5 15ZM4.5 4.5H5.25V5.25C5.25 5.6625 5.5875 6 6 6C6.4125 6 6.75 5.6625 6.75 5.25V4.5H11.25V5.25C11.25 5.6625 11.5875 6 12 6C12.4125 6 12.75 5.6625 12.75 5.25V4.5H13.5C13.9132 4.5 14.25 4.83675 14.25 5.25V8.25H3.75V5.25C3.75 4.83675 4.08675 4.5 4.5 4.5ZM13.5 3H12.75V2.25C12.75 1.8375 12.4125 1.5 12 1.5C11.5875 1.5 11.25 1.8375 11.25 2.25V3H6.75V2.25C6.75 1.8375 6.4125 1.5 6 1.5C5.5875 1.5 5.25 1.8375 5.25 2.25V3H4.5C3.2595 3 2.25 4.0095 2.25 5.25V14.25C2.25 15.4905 3.2595 16.5 4.5 16.5H13.5C14.7405 16.5 15.75 15.4905 15.75 14.25V5.25C15.75 4.0095 14.7405 3 13.5 3Z'
                fill='#B3B5C9'
            />
            <mask
                id='mask0'
                mask-type='alpha'
                maskUnits='userSpaceOnUse'
                x={2}
                y={1}
                width={14}
                height={16}>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M5.25 12C5.25 11.5875 5.5875 11.25 6 11.25C6.4125 11.25 6.75 11.5875 6.75 12C6.75 12.4125 6.4125 12.75 6 12.75C5.5875 12.75 5.25 12.4125 5.25 12ZM9 11.25H12C12.4125 11.25 12.75 11.5875 12.75 12C12.75 12.4125 12.4125 12.75 12 12.75H9C8.5875 12.75 8.25 12.4125 8.25 12C8.25 11.5875 8.5875 11.25 9 11.25ZM13.5 15H4.5C4.08675 15 3.75 14.6632 3.75 14.25V9.75H14.25V14.25C14.25 14.6632 13.9132 15 13.5 15ZM4.5 4.5H5.25V5.25C5.25 5.6625 5.5875 6 6 6C6.4125 6 6.75 5.6625 6.75 5.25V4.5H11.25V5.25C11.25 5.6625 11.5875 6 12 6C12.4125 6 12.75 5.6625 12.75 5.25V4.5H13.5C13.9132 4.5 14.25 4.83675 14.25 5.25V8.25H3.75V5.25C3.75 4.83675 4.08675 4.5 4.5 4.5ZM13.5 3H12.75V2.25C12.75 1.8375 12.4125 1.5 12 1.5C11.5875 1.5 11.25 1.8375 11.25 2.25V3H6.75V2.25C6.75 1.8375 6.4125 1.5 6 1.5C5.5875 1.5 5.25 1.8375 5.25 2.25V3H4.5C3.2595 3 2.25 4.0095 2.25 5.25V14.25C2.25 15.4905 3.2595 16.5 4.5 16.5H13.5C14.7405 16.5 15.75 15.4905 15.75 14.25V5.25C15.75 4.0095 14.7405 3 13.5 3Z'
                    fill='#B3B5C9'
                />
            </mask>
            <g mask='url(#mask0)'>
                <rect width={18} height={18} fill='#B3B5C9' />
            </g>
        </svg>
    )
}

export default IconCalendar
