import React from 'react'
import Container from '../Container/Container'
import { Link } from 'react-router-dom'
import './Services.scss'

/* Svg Icons: */
import NextArrow from '../Lib/Svg/NextArrow'

function Services() {
	return (
		<section className='services'>
			<Container>
				<div className='services__wrapper'>
					<h2 className='services__heading'>
						Biz nima <br />
						<span className='services__heading-highlighted'>Taklif etamiz</span>
					</h2>

					<ul className='services__list'>
						<li className='services__item services__item-programming'>
							<Link className='services__link'>
								<h3 className='services__title'>Dasturiy ta'minot</h3>
								<p className='services__desc'>
								Bularga veb-saytlar, boshqaruv panellari, telegram botlari, tizim yechimlari va boshqalar kiradi.
								</p>

								<NextArrow className='services__next-arrow-icon' />
							</Link>
						</li>

						<li className='services__item services__item-design'>
							<Link className='services__link'>
								<h3 className='services__title'>
									UX & UI <br /> dizayn
								</h3>
								<p className='services__desc'>
								Bularga veb va mobil ilovalar uchun foydalanuvchi interfeysi va tajribasini loyihalash, prototiplash, brending va vizual dizayn yechimlari kiradi
								</p>

								<NextArrow className='services__next-arrow-icon' />
							</Link>
						</li>

						<li className='services__item services__item-marketing'>
							<Link className='services__link'>
								<h3 className='services__title'>
									SMM va <br /> marketing
								</h3>
								<p className='services__desc'>
								Bularga ijtimoiy tarmoqlarni yuritish, kontent yaratish va reklama kampaniyalari kiradi.
								</p>

								<NextArrow className='services__next-arrow-icon' />
							</Link>
						</li>
					</ul>
				</div>
			</Container>
		</section>
	)
}

export default Services
