import React from 'react'

function IconReview() {
    return (
<svg width={40} height={40} viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M28.009 18.0645C28.4252 18.0645 28.8161 17.9026 29.1103 17.6084L31.2348 15.4839H38.0645C39.1316 15.4839 40 14.6155 40 13.5484V4.51613C40 3.44903 39.1316 2.58065 38.0645 2.58065H24.5161V1.29032C24.5161 0.57871 23.9374 0 23.2258 0H5.42839C5.08387 0 4.76 0.134194 4.51613 0.378064L0.378064 4.51613C0.134194 4.76 0 5.08387 0 5.42839V38.7097C0 39.4213 0.57871 40 1.29032 40H23.2258C23.9374 40 24.5161 39.4213 24.5161 38.7097V33.5484H30.7006L32.8252 35.6729C33.1194 35.9671 33.5103 36.129 33.9264 36.129C34.7852 36.129 35.4839 35.4303 35.4839 34.5716V33.5484H38.0645C39.1316 33.5484 40 32.68 40 31.6129V22.5806C40 21.5135 39.1323 20.6452 38.0658 20.6452H24.5161V15.4839H26.4516V16.5071C26.4516 17.3658 27.1503 18.0645 28.009 18.0645ZM4.51613 2.20258V4.51613H2.20258L4.51613 2.20258ZM1.29032 38.7097V5.80645H5.80645V1.29032H23.2258V2.69935C22.4768 2.96645 21.9355 3.67613 21.9355 4.51613V13.5484C21.9355 14.3884 22.4768 15.0981 23.2258 15.3652V20.7645C22.4768 21.0316 21.9355 21.7413 21.9355 22.5813V31.6135C21.9355 32.4535 22.4768 33.1632 23.2258 33.4303V38.7097H1.29032ZM38.7097 22.5806V31.6129C38.7097 31.9684 38.42 32.2581 38.0645 32.2581H34.1935V34.5716C34.1935 34.7897 33.891 34.9142 33.7374 34.7606L31.2348 32.2581H23.871C23.5155 32.2581 23.2258 31.9684 23.2258 31.6129V22.5806C23.2258 22.2252 23.5155 21.9355 23.871 21.9355H38.0658C38.4213 21.9355 38.7097 22.2252 38.7097 22.5806ZM23.871 14.1935C23.5155 14.1935 23.2258 13.9039 23.2258 13.5484V4.51613C23.2258 4.16065 23.5155 3.87097 23.871 3.87097H38.0645C38.42 3.87097 38.7097 4.16065 38.7097 4.51613V13.5484C38.7097 13.9039 38.42 14.1935 38.0645 14.1935H30.7006L28.1981 16.6961C28.0439 16.8497 27.7419 16.7252 27.7419 16.5071V14.1935H23.871Z" fill="currentColor" />
  <path d="M36.5632 6.28388L35.6948 5.32904L29.0535 11.3671L27.5529 9.86646L26.6406 10.7787L29.0109 13.149L36.5632 6.28388Z" fill="currentColor" />
  <path d="M16.7742 21.9355H2.58064V27.0968H16.7742V21.9355ZM15.4839 25.8065H3.87097V23.2258H15.4839V25.8065Z" fill="currentColor" />
  <path d="M2.58064 28.3871H16.7742V29.6774H2.58064V28.3871Z" fill="currentColor" />
  <path d="M2.58064 30.9677H19.3548V32.2581H2.58064V30.9677Z" fill="currentColor" />
  <path d="M2.58064 33.5484H19.3548V34.8387H2.58064V33.5484Z" fill="currentColor" />
  <path d="M2.58064 36.129H6.45161V37.4194H2.58064V36.129Z" fill="currentColor" />
  <path d="M7.74194 36.129H11.6129V37.4194H7.74194V36.129Z" fill="currentColor" />
  <path d="M12.9032 36.129H16.7742V37.4194H12.9032V36.129Z" fill="currentColor" />
  <path d="M12.2581 3.87097C7.63355 3.87097 3.87097 7.63355 3.87097 12.2581C3.87097 16.8826 7.63355 20.6452 12.2581 20.6452C16.8826 20.6452 20.6452 16.8826 20.6452 12.2581C20.6452 7.63355 16.8826 3.87097 12.2581 3.87097ZM12.2581 19.3548C10.2394 19.3548 8.42 18.5039 7.12645 17.1465C7.60581 16.3335 8.26129 15.6497 9.04064 15.1497L12.2581 18.3258L15.4755 15.1497C16.2548 15.6497 16.9103 16.3335 17.3897 17.1465C16.0961 18.5039 14.2768 19.3548 12.2581 19.3548ZM9.67742 10.3226C9.67742 8.89935 10.8348 7.74194 12.2581 7.74194C13.6813 7.74194 14.8387 8.89935 14.8387 10.3226C14.8387 11.7458 13.6813 12.9032 12.2581 12.9032C10.8348 12.9032 9.67742 11.7458 9.67742 10.3226ZM10.2729 14.5529C10.9039 14.3277 11.5716 14.1935 12.2581 14.1935C12.9445 14.1935 13.6123 14.3277 14.2432 14.5529L12.2581 16.5129L10.2729 14.5529ZM18.2419 16.0574C17.3723 14.7955 16.1181 13.8432 14.6897 13.3303C15.5665 12.62 16.129 11.5361 16.129 10.3226C16.129 8.18774 14.3929 6.45161 12.2581 6.45161C10.1232 6.45161 8.3871 8.18774 8.3871 10.3226C8.3871 11.5361 8.94968 12.62 9.82645 13.3303C8.39807 13.8432 7.14387 14.7955 6.27419 16.0574C5.57355 14.9574 5.16129 13.6561 5.16129 12.2581C5.16129 8.34516 8.34516 5.16129 12.2581 5.16129C16.171 5.16129 19.3548 8.34516 19.3548 12.2581C19.3548 13.6561 18.9426 14.9574 18.2419 16.0574Z" fill="currentColor" />
  <path d="M28.198 30.7787L30.9677 28.009L33.7374 30.7787L34.6497 29.8664L31.88 27.0968L34.6497 24.3271L33.7374 23.4148L30.9677 26.1845L28.198 23.4148L27.2858 24.3271L30.0555 27.0968L27.2858 29.8664L28.198 30.7787Z" fill="currentColor" />
</svg>


    )
}

export default IconReview
