import React from 'react'

function IconResearch() {
    return (
        <svg
            width={41}
            height={40}
            viewBox='0 0 41 40'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M12.7522 28.7181C13.3132 28.7835 13.8774 28.8161 14.4424 28.8161C17.2056 28.8164 19.9093 28.0142 22.225 26.5065L34.7946 39.0738C35.9589 40.2789 37.8796 40.3125 39.0848 39.1482C40.2899 37.9843 40.3232 36.0636 39.1592 34.8584C39.1348 34.8331 39.1101 34.8081 39.0848 34.7836L26.5151 22.2143C30.8266 15.5273 28.901 6.61157 22.214 2.30009C15.5273 -2.01109 6.61157 -0.0855145 2.30009 6.60119C-2.01109 13.2879 -0.0855145 22.2036 6.60119 26.5151C8.45564 27.7108 10.5601 28.4645 12.7522 28.7181ZM25.6146 23.4588L26.7526 24.5965L24.6075 26.7416L23.4704 25.6045C24.2583 24.9662 24.9769 24.247 25.6146 23.4588ZM38.4565 36.9287C38.4578 37.3313 38.2978 37.7175 38.0121 38.0014C37.4122 38.5747 36.4672 38.5747 35.8673 38.0014L25.6768 27.8109L27.8219 25.6662L38.0121 35.8563C38.2978 36.1399 38.4578 36.5264 38.4565 36.9287ZM1.60562 12.9158C2.42677 5.84201 8.82684 0.773486 15.9009 1.59463C22.9747 2.41578 28.0432 8.81585 27.2221 15.8899C26.4009 22.9637 20.0005 28.0322 12.9268 27.2111C5.85697 26.3826 0.792107 19.9874 1.60562 12.9158Z'
                fill='currentColor'
            />
            <path
                d='M8.29227 6.67261C10.3387 5.04222 12.953 4.29891 15.551 4.60814C15.9683 4.64508 16.3365 4.33677 16.3734 3.91948C16.4091 3.51806 16.1246 3.15908 15.7256 3.10169C9.48401 2.3767 3.8364 6.84844 3.11111 13.0901C3.04731 13.6301 3.02197 14.1744 3.0354 14.718C3.04639 15.1289 3.38279 15.4561 3.79367 15.4558H3.81412C4.23294 15.4448 4.56323 15.0965 4.55224 14.6777C4.54033 14.2064 4.5614 13.7348 4.61512 13.2662C4.91092 10.6654 6.23513 8.29109 8.29227 6.67261Z'
                fill='currentColor'
            />
            <path
                d='M16.4415 15.5001C16.2483 15.4156 16.0441 15.3591 15.8347 15.3316C15.1411 15.2553 14.4506 15.5023 13.9628 16.0014L11.315 14.7635C11.4481 13.5138 10.543 12.3929 9.29356 12.2598C8.04382 12.1267 6.92291 13.0318 6.78951 14.2812C6.73396 14.8051 6.86155 15.3322 7.15094 15.7724L5.75377 17.5875C5.50559 17.9248 5.57763 18.3995 5.91525 18.648C6.24249 18.8885 6.70129 18.8287 6.95588 18.5121L8.34481 16.7068C8.4895 16.7538 8.63877 16.7865 8.78988 16.8039C8.87779 16.8146 8.96601 16.8198 9.05454 16.8198C9.66139 16.817 10.2417 16.5713 10.6657 16.1372L13.3105 17.3735C13.1908 18.6058 14.0788 19.7078 15.3084 19.8531C15.396 19.8635 15.4839 19.869 15.5721 19.869C16.8261 19.8708 17.8439 18.8559 17.8457 17.6018C17.846 17.2224 17.7514 16.8485 17.5701 16.5148L19.075 14.853C20.1947 15.3576 21.5116 14.8588 22.0158 13.7388C22.1309 13.4836 22.1966 13.2092 22.2097 12.9295L24.0907 12.4192C24.4952 12.3093 24.7342 11.8926 24.6243 11.4881C24.5147 11.0836 24.0977 10.8446 23.6932 10.9545L21.8147 11.4649C21.1095 10.4313 19.7002 10.1648 18.6662 10.8697C17.7035 11.5266 17.3957 12.8065 17.9553 13.8291L16.4415 15.5001ZM8.9651 15.2974C8.54903 15.248 8.25201 14.8707 8.30146 14.4549C8.34664 14.0743 8.66838 13.7873 9.05149 13.7858C9.08109 13.7858 9.1104 13.7873 9.1397 13.7904C9.55577 13.8386 9.85401 14.215 9.80578 14.6311C9.75785 15.0474 9.38147 15.3457 8.9651 15.2974ZM16.0419 18.1867C15.7132 18.4462 15.236 18.39 14.9766 18.061C14.7171 17.7322 14.7733 17.2554 15.102 16.9959C15.2351 16.8906 15.3997 16.8332 15.5694 16.8326C15.5993 16.8329 15.6292 16.8347 15.6588 16.8381C16.0746 16.8878 16.3713 17.2658 16.3212 17.6815C16.2974 17.8809 16.1955 18.0625 16.038 18.1867H16.0419ZM19.2099 12.5913C19.3009 12.1823 19.706 11.9243 20.1147 12.015C20.5237 12.1057 20.7817 12.5107 20.691 12.9198C20.6004 13.3285 20.1953 13.5868 19.7862 13.4961C19.59 13.4525 19.419 13.3328 19.3109 13.1631C19.2029 12.9933 19.1666 12.7876 19.2099 12.5913Z'
                fill='currentColor'
            />
        </svg>
    )
}

export default IconResearch
