import Hero from '../../components/Hero/Hero'
import Statistics from '../../components/Statistics/Statistics'
import Services from '../../components/Services/Services'

function Home() {
	return (
		<>
			<Hero />
			<Statistics />
			<Services />
			{/* <HomeTeam /> */}
			{/* <HomeTool /> */}
			{/* <HomeNews /> */}
			{/* <Partners /> */}
		</>
	)
}

export default Home
