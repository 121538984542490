import React, { useState } from 'react'
import './Header.scss'
import Container from '../Container/Container'
import { Link, NavLink } from 'react-router-dom'
/* Svg Icons: */
import LogoImg from '../Lib/Svg/Logo'
import PhoneCall from '../Lib/Svg/PhoneCall'
import Hamburger from '../Lib/Svg/Hamburger'
import Close from '../Lib/Svg/Close'

function Header() {
	const elHumburger = React.useRef()

	const [x, setx] = useState(false)

	return (
		<header className='site-header'>
			<Container>
				<div className='site-header__wrapper' ref={elHumburger}>
					<NavLink className='logo' to='/'>
						<LogoImg className='logo__img' />
					</NavLink>

					<div className='site-header__features'>
						<a className='site-header__tel-link' href='tel:+998996130011'>
							<PhoneCall className='site-header__phonecall' />
							+998 99 613 00 11
						</a>
					</div>

					
					<ul className='site-header__menu-list'>
						<li className='site-header__menu-item'>
							<Link className='site-header__menu-link' to='/' exact>Главная</Link>
						</li>

						<li className='site-header__menu-item'>
							<Link className='site-header__menu-link' to='/services'>Сервисы</Link>
						</li>

						<li className='site-header__menu-item'>
							<Link className='site-header__menu-link' to='/articles'>Ресурсы</Link>
						</li>

						<li className='site-header__menu-item'>
							<Link className='site-header__menu-link' to='/projects'>Проекты</Link>
						</li>

						<li className='site-header__menu-item'>
							<Link className='site-header__menu-link' to='/contacts'>Контакты</Link>
						</li>
					</ul>
				</div>
			</Container>
		</header>
	)
}

export default Header
