function Insta () {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.005 7.29663C10.7513 7.29663 7.30762 10.7441 7.30762 14.994C7.30762 19.2477 10.7551 22.6914 15.005 22.6914C19.2587 22.6914 22.7023 19.2439 22.7023 14.994C22.7023 10.7403 19.2549 7.29663 15.005 7.29663ZM15.005 19.9902C12.2438 19.9902 10.0088 17.7539 10.0088 14.994C10.0088 12.234 12.245 9.99783 15.005 9.99783C17.7649 9.99783 20.0011 12.234 20.0011 14.994C20.0024 17.7539 17.7662 19.9902 15.005 19.9902Z" fill="#00094A"/>
        <path d="M21.1849 0.0942317C18.425 -0.0345161 11.5888 -0.0282662 8.82638 0.0942317C6.39892 0.20798 4.25771 0.794219 2.53149 2.52044C-0.35346 5.40539 0.0152838 9.29282 0.0152838 14.994C0.0152838 20.8289 -0.30971 24.6263 2.53149 27.4675C5.42769 30.3625 9.37137 29.9837 15.005 29.9837C20.7849 29.9837 22.7799 29.9875 24.8236 29.1962C27.6023 28.1175 29.6998 25.6338 29.9048 21.1726C30.0348 18.4114 30.0273 11.5765 29.9048 8.81408C29.6573 3.54792 26.8311 0.354227 21.1849 0.0942317ZM25.5536 25.5588C23.6624 27.45 21.0387 27.2813 14.9688 27.2813C8.71888 27.2813 6.21268 27.3738 4.38396 25.54C2.27774 23.4438 2.65899 20.0776 2.65899 14.974C2.65899 8.06784 1.95025 3.09418 8.88138 2.73919C10.4739 2.68294 10.9426 2.66419 14.9513 2.66419L15.0075 2.70169C21.6687 2.70169 26.8948 2.0042 27.2086 8.93408C27.2798 10.5153 27.2961 10.9903 27.2961 14.9927C27.2948 21.1701 27.4123 23.6913 25.5536 25.5588Z" fill="#00094A"/>
        <path d="M23.0075 8.79153C24.0009 8.79153 24.8062 7.98621 24.8062 6.99281C24.8062 5.99941 24.0009 5.19409 23.0075 5.19409C22.0141 5.19409 21.2087 5.99941 21.2087 6.99281C21.2087 7.98621 22.0141 8.79153 23.0075 8.79153Z" fill="#00094A"/>
        </svg>
        
        )
    }
export default Insta